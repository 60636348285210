import { useDispatch, useSelector } from 'react-redux';
import { appState, setIframePopup } from '../store/reducers/appReducer';
import useAuth from './useAuth';
import { currentDistinctId } from './useAnalytics';
import { paymentUrl } from '../config/getdomain';

const useIframeHelper = () => {
  const dispatch = useDispatch();
  const { currency: { code } } = useSelector(appState);
  const { renewToken, user } = useAuth();

  const openIframePopup = async (url = 'plan', params = {}) => {
    switch (url) {
      case 'plan': {
        const param = {
          embed: true,
          distinctId: currentDistinctId(),
          currency: code,
          type: 'personal',
          ...params,
        };
        try {
          if (user) {
            const newToken = await renewToken();
            param.token = newToken;
          }
        } catch {
          // do nothing
        }

        dispatch(setIframePopup({ open: true, url: `${paymentUrl()}/Plan?${new URLSearchParams(param)}`, isSticky: false }));
        break;
      }
      default:
        dispatch(setIframePopup({ open: true, url, isSticky: false }));
        break;
    }
  };

  const closeIframePopup = () => {
    dispatch(setIframePopup({ open: false, url: '', isSticky: false }));
  };

  return { openIframePopup, closeIframePopup };
};

export default useIframeHelper;
