/* eslint-disable no-return-assign */
import { useEffect, useRef } from 'react';

const useScrollGrab = ({ data, childSelector }) => {
  const ref = useRef(null);

  useEffect(() => {
    const slider = ref.current;

    const ele = slider;
    const childs = document.querySelectorAll(childSelector);
    if (ele) {
      ele.scrollLeft = 0;
      let position = {
        left: 0,
        y: 0,
        x: 0,
      };

      const mouseMoveHandler = (e) => {
        const dx = e.clientX - position.x;
        ele.scrollLeft = position.left - dx;
      };

      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);

        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
        [...childs].map((v) => v.style.cursor = 'pointer');
      };

      const mouseDownHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        [...childs].map((v) => v.style.cursor = 'grabbing');
        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';
        position = {
          ...position,
          left: ele.scrollLeft,
          x: e.clientX,
        };

        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      ele.addEventListener('mousedown', mouseDownHandler);
    }
  }, [data]);

  return ref;
};

export default useScrollGrab;
