import { useEffect } from 'react';
import { getAuth, onIdTokenChanged, signOut } from 'firebase/auth';
import useAuth from './useAuth';

const useFirebaseAuthCheck = (logoutCallback) => {
    const { logout, user: _user } = useAuth();

    useEffect(() => {
        const auth = getAuth();
        let unsubscribe = null;

        const handleTokenCheck = async (user) => {
            if (user || _user) {
                try {
                    const token = await user.getIdToken(true);
                    console.log('Token valid:', token);
                } catch (error) {
                    logout();
                }
            }
        };

        setTimeout(() => {
            unsubscribe = onIdTokenChanged(auth, handleTokenCheck);
        }, 200);

        return () => unsubscribe?.();
    }, [logoutCallback]);

};

export default useFirebaseAuthCheck;
