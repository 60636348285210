/* eslint-disable no-return-assign */
/* eslint-disable react/no-danger */
import { create } from 'zustand';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Marquee from 'react-fast-marquee';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { ImageResizer } from 'assemblr-ui';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import posthog from 'posthog-js';
import { MdClose } from 'react-icons/md';
import MainLayout from '../components/layout/main';
import classnames from '../utils/classnames';
import userAgent from '../utils/userAgent';
import { loginPopupAnalyticTracking } from '../components/controllers/analytic_tracking';
import { setLogin } from '../store/actions/popupActions';
import useAuth from '../hooks/useAuth';
import { setUnityVersion } from '../store/reducers/helperReducer';
import useIframeHelper from '../hooks/useIframeHelper';
import useScrollGrab from '../hooks/useScrollGrab';
import AppServices from '../services/AppService';
import useFirebaseAuthCheck from '../hooks/useFirebaseAuthCheck';

function TutorialCard({
  video, thumbnail, isFirst, isLast, title, onClick, color, boxShadow,
}) {
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef(null);

  return (
    <div
      ref={ref}
      aria-hidden
      className={classnames('tutorial-card rounded-xl overflow-hidden h-[280px] w-[224px] min-w-[224px] flex flex-col cursor-pointer relative', {
        'ml-5 md:ml-10': isFirst,
        'mr-5 md:mr-10': isLast,
      })}
      style={{
        background: color,
        // border: `2px solid ${color}`,
        boxShadow,
      }}
      onMouseEnter={() => {
        if (video) {
          videoRef.current?.play?.();
          setIsPlaying(true);
        }
      }}
      onMouseLeave={() => {
        if (video) {
          videoRef.current?.pause?.();
          setIsPlaying(false);
        }
      }}
      onClick={() => {
        if (!ref.current.onscroll) {
          onClick();
        }
      }}
    >
      {video && (
        <video
          ref={videoRef}
          poster={thumbnail}
          className="w-full aspect-square rounded-lg rounded-b-none"
          muted
          playsInline
        >
          <source src={video} type="video/mp4" />
        </video>
      )}

      <div className="bg-transparent w-full aspect-square rounded-lg rounded-b-none absolute z-10" />

      {
        thumbnail && (
          <img
            src={thumbnail}
            alt={title}
            className={classnames('w-full min-w-full aspect-square rounded-lg rounded-b-none absolute top-0 left-0 z-[2]', {
              'opacity-0': isPlaying,
            })}
          />
        )
      }
      <div className="flex justify-center items-center shrink-0 h-[56px]">
        <h3 className="font-plus-jakarta-sans text-center text-white mx-4">{title}</h3>
      </div>
    </div>
  );
}

const useTutorialStore = create((set) => ({
  data: [
    {
      title: 'AR on graphics',
      description: 'Suitable if you want to display interactive experience above visual objects like books, posters, stickers, and other printed objects',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-on-top-marker.mp4',
      thumbnail: '/assets/images/home/tutor-01.png',
      youtube_id: 'vKvkc-mPXHk',
      color: '#63202F',
      accent: '#7B283B',
      boxShadow: '0px 30px 25px 0px rgba(0, 0, 0, 0.10), 0px 5px 15px 0px rgba(0, 0, 0, 0.40), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
      detail: {
        title: 'Create 3D AR Above Your Graphics',
        video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-on-top-marker.mp4',
        subtitle: 'Start creating interactive 3D projects effortlessly.',
        description: 'Add AR elements on top of your graphic designs and make them scannable.',
        cta: 'Create AR Project',
        poster: '/assets/images/home/tutor-01.png',
        youtubeId: 'bgkolApj-YU',
      },
    },
    {
      title: 'Visualize Products with 3D AR',
      description: 'With real-world size and plane detection, you can preview virtual objects such as furniture, machines, and other physical objects inside your home',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-without-marker.mp4',
      thumbnail: '/assets/images/home/tutor-02.png',
      youtube_id: 'U5sAlPspmbA',
      color: '#224F2C',
      accent: '#367D46',
      boxShadow: '0px 30px 25px 0px rgba(0, 0, 0, 0.10), 0px 5px 15px 0px rgba(0, 0, 0, 0.40), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
      detail: {
        title: 'Create 3D AR Above Your Graphics',
        video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-on-top-marker.mp4',
        subtitle: 'Unleash your creativity with our easy-to-use tools. ',
        description: 'Add 3D AR visualizations to your product images.',
        cta: 'Start Now',
        poster: '/assets/images/home/tutor-02.png',
        youtubeId: 'P0NfbiXVd1M',
      },
    },
    {
      title: 'AR interactive quizzes',
      description: 'With an easy-to-use interactivity builder, you can create an engaging interactive experience such as quiz, mini-game, or simple simulation',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-for-3d-interactive.mp4',
      thumbnail: '/assets/images/home/tutor-03.png',
      youtube_id: 'IfoIjZu1GxM',
      color: '#1F468A',
      accent: '#2759AF',
      boxShadow: '0px 30px 25px 0px rgba(0, 0, 0, 0.10), 0px 5px 15px 0px rgba(0, 0, 0, 0.40), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
      detail: {
        title: 'Craft AR Interactive Quizzes',
        video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-for-3d-interactive.mp4',
        subtitle: 'Dive into the world of 3D and AR with Assemblr Studio. ',
        description: 'Create interactive quizzes that utilize 3D AR for an engaging experience.',
        cta: 'Begin Creating',
        poster: '/assets/images/home/tutor-03.png',
        youtubeId: 'Pk5EV5aYg9Y',
      },
    },
    {
      title: '3D on websites',
      description: 'Display your interactive 3D content inside your website, blog posts, or Canva presentations',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-for-web-interactive.mp4',
      thumbnail: '/assets/images/home/tutor-04.png',
      youtube_id: 'feNhgOUMbnU',
      color: '#8F6A21',
      accent: '#B6882A',
      boxShadow: '0px 30px 25px 0px rgba(0, 0, 0, 0.10), 0px 5px 15px 0px rgba(0, 0, 0, 0.40), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
      detail: {
        title: 'Integrate 3D Elements on Your Website',
        video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/ar-on-top-marker.mp4',
        subtitle: 'Explore the possibilities of 3D design today.',
        description: 'Embed 3D elements on your website for a dynamic user experience.',
        cta: 'Try Now',
        poster: '/assets/images/home/tutor-04.png',
        youtubeId: '-GVdGDzSo0M',
      },
    },
    {
      title: '3D on Canva',
      thumbnail: '/assets/images/home/tutor-05.png',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/embed-on-canva.mp4',
      color: '#663CAE',
      accent: '#7B51C3',
      boxShadow: '0px 30px 25px 0px rgba(0, 0, 0, 0.10), 0px 5px 15px 0px rgba(0, 0, 0, 0.40), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
      youtube_id: 'TysGErOZ_cY',
      detail: {
        title: 'Embed 3D Projects on Canva',
        video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/embed-on-canva.mp4',
        subtitle: 'Enrich your Canva project with 3D contents ',
        description: 'Embed your Assemblr project into your Canva projects as simple as copy-paste',
        cta: 'Try Now',
        poster: '/assets/images/home/tutor-05.png',
        youtubeId: 'TysGErOZ_cY',
      },
    },
  ],
  selectedTutorial: null,
  setSelectedTutorial: (selectedTutorial) => set({ selectedTutorial }),
}));

const usePartnerStore = create(() => ({
  data: [
    {
      title: 'Apple',
      image: '/assets/images/partners/apple.png',
      sort: -1,
      height: 43,
    },
    {
      title: 'BCA',
      image: '/assets/images/partners/bca.webp',
      sort: 9,
      height: 35,
    },
    {
      title: 'Bank Indonesia',
      image: '/assets/images/partners/bi.webp',
      sort: 4,
      height: 19,
    },
    {
      title: 'Cikal',
      image: '/assets/images/partners/cikal.png',
      sort: 12,
      height: 39,
    },
    {
      title: 'Dekoruma',
      image: '/assets/images/partners/dekoruma.webp',
      sort: 8,
      height: 20,
    },
    {
      title: 'Ducati',
      image: '/assets/images/partners/ducati.webp',
      sort: 3,
      height: 25,
    },
    {
      title: 'Google',
      image: '/assets/images/partners/google.webp',
      sort: 1,
      height: 35,
    },
    {
      title: 'Mizan',
      image: '/assets/images/partners/mizan.webp',
      sort: 11,
      height: 37,
    },
    {
      title: 'Pertamina',
      image: '/assets/images/partners/pertamina.webp',
      sort: 7,
      height: 23,
    },
    {
      title: 'Rusanimation',
      image: '/assets/images/partners/rusanimation.webp',
      sort: 13,
      height: 19,
    },
    {
      title: 'Telkomsel',
      image: '/assets/images/partners/telkomsel.webp',
      sort: 10,
      height: 23,
    },
    {
      title: 'WWF',
      image: '/assets/images/partners/wwf.webp',
      sort: 5,
      height: 49,
    },
    {
      title: 'Xiaomi',
      image: '/assets/images/partners/xiaomi.webp',
      sort: 6,
      height: 43,
    },
    {
      title: 'Youtube Music',
      image: '/assets/images/partners/youtube-music.webp',
      sort: 2,
      height: 20,
    },
  ].sort((a, b) => a.sort - b.sort),
}));

const usePlanStore = create((set, get) => ({
  data: [
    {
      title: 'Free Plan',
      description: 'Start free,<br/> perfect for beginners',
      thumbnail: '/assets/images/home/plans/free.png',
      style: {
        background: '#E8EBFC',
        boxShadow: '0px 25px 50px 0px rgba(0, 0, 0, 0.30), 0px 5px 10px 0px rgba(0, 0, 0, 0.25)',
      },
      cta: {
        text: 'Start for Free',
        onClick: 'signup',
      },
      key: 'free',
    },
    {
      title: 'Basic Plan',
      description: '<b>300 MB</b> storage capacity<br/> suitable for daily uses',
      thumbnail: '/assets/images/home/plans/basic.png',
      style: {
        background: '#FCE1DA',
        boxShadow: '0px 25px 50px 0px rgba(0, 0, 0, 0.30), 0px 5px 10px 0px rgba(0, 0, 0, 0.25)',
      },
      price: 9.9,
      cta: {
        text: 'Subscribe',
        url: '/explore?model=subscription&duration=monthly&content=checkout&currency=USD&group=basic',
      },
      key: 'basic',
    },
    {
      title: 'Medium Plan',
      description: '<b>500 MB</b> storage capacity<br/> for creative enthusiasts',
      thumbnail: '/assets/images/home/plans/medium.png',
      style: {
        background: '#BDFFEC',
        boxShadow: '0px 25px 50px 0px rgba(0, 0, 0, 0.30), 0px 5px 10px 0px rgba(0, 0, 0, 0.25)',
      },
      price: 29.9,
      cta: {
        text: 'Subscribe',
        url: '/explore?model=subscription&duration=monthly&content=checkout&currency=USD&group=medium',
      },
      key: 'medium',
    },
    {
      title: 'Premium Plan',
      description: '<b>1 GB</b> storage capacity<br/> for work & teams',
      thumbnail: '/assets/images/home/plans/premium.png',
      style: {
        background: '#C0D3FF',
        boxShadow: '0px 25px 50px 0px rgba(0, 0, 0, 0.30), 0px 5px 10px 0px rgba(0, 0, 0, 0.25)',
      },
      price: 49.9,
      cta: {
        text: 'Subscribe',
        url: '/explore?model=subscription&duration=monthly&content=checkout&currency=USD&group=premium',
      },
      key: 'premium',
    },
  ],
  serverData: [],
  loading: true,
  getServerData: async () => {
    try {
      set({ loading: true });
      const response = await fetch('https://asblr.app/api/plans/filter/public?type=personal');
      const { data } = await response.json();
      set({ serverData: data, loading: false });
    } catch (error) {
      console.error(error);
      set({ loading: false });
    }
  },
  iapId: (key) => get().serverData?.[key]?.monthly?.USD?.iap_product_id,
}));

const useFeatureStore = create(() => ({
  data: [
    {
      key: '3d-discover',
      title: 'Discover thousands of<br/> ready-to-use 3D collections',
      description: 'For any kind of project, get ready to use our 3D objects—available in various themes. You can also upload any 3D objects from other sources—just in case you don’t find any that suit you.',
      cta: 'Explore our 3D',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/home-3d-section.mp4',
      url: '/marketplace',
      thumbnail: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/SF01.png',
      nextRouter: true,
    },
    {
      key: 'interactivity',
      title: 'Give life to your projects<br/> with Interactivity',
      description: 'Insert basic animations into your objects with our easy-to-use Interactivity—suitable for quizzes, presentations, or simple interactive games. Give a bit of a twist to your project!',
      cta: 'Learn How',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/home-interactivity-section.mp4',
      url: 'https://guide.assemblrworld.com/assemblr-studio-editor/interactivity-feature',
      thumbnail: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/SF02.png',
    },
    {
      key: 'color-up',
      title: 'Color up your projects with<br/> various elements',
      description: 'Add visual elements like 3D texts, 2D objects, audio, and video to your projects as details. The more visual elements, the more eye-catching it is!',
      cta: 'Learn how',
      thumbnail: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/SF03.png',
      video: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/home-media-section.mp4',
      url: 'https://guide.assemblrworld.com/assemblr-studio-editor/introduction-to-editor',
    },
    {
      key: 'share-and-enliven',
      title: 'Share & enliven your projects<br/> on your daily gadgets',
      description: 'Be it on our mobile app or web browser, you can share or embed your projects—simply with a link or the embed code. Make it alive, anywhere you go!',
      cta: 'Give it a try',
      image: '/assets/images/home/share-and-enliven.png',
      url: 'https://guide.assemblrworld.com/share-projects/share-projects-link',
    },
  ],
}));

function SectionFeature({
  title, description, cta, reverse, handleCta, image, video, id, thumbnail,
}) {
  return (
    <div className={classnames('w-full overflow-hidden', {
      '': !reverse,
      '!overflow-visible': id === '3d-discover',
      'bg-[#D4F9F74D]': id === 'color-up',
      'z-10 bg-white': id === 'interactivity',
    })}
    >
      <div className={classnames('w-[calc(100vw-40px)] md:w-[calc(100vw-80px)] max-w-[1200px] flex gap-11 lg:gap-24 items-center py-[50px] lg:py-[100px] mx-auto flex-col md:flex-row relative overflow-visible', {
        'md:!flex-row-reverse': reverse,
      })}
      >
        <div
          className={classnames('md:w-1/2 max-w-full object-cover rounded-lg bg-white z-[2] relative', {
            'home-section-card shadow-xl': video,
          })}
        >

          {id === '3d-discover' && (<div className="bg-home-3d-discover-vector !-z-[1]" />)}

          {id === 'interactivity' && (<div className="bg-home-interactivity-vector !-z-[1]" />)}

          {id === 'color-up' && (<div className="bg-home-color-up-vector !-z-[1]" />)}

          {id === 'share-and-enliven' && (<div className="bg-home-share-vector !-z-[1]" />)}

          {
            image && (
              <img
                src={image}
                alt="feature"
                className="max-w-[80%] mx-auto rounded-lg object-cover z-[2]"
              />
            )
          }
          {
            video && (
              <video
                autoPlay
                loop
                muted
                playsInline
                poster={thumbnail}
                src={video}
                alt="feature"
                className="w-full object-cover rounded-lg !home-section-card bg-white z-[2]"
              />
            )
          }
          {
            !image && !video && (
              <div className="w-[500px] home-section-card md:min-w-[500px] !max-w-full aspect-[4/3] bg-[#2B2A35] rounded-lg" />
            )
          }
        </div>
        <div className="w-full md:w-1/2 flex flex-col max-w-1/2 lg:max-w-[500px]">
          <h3
            dangerouslySetInnerHTML={{ __html: title }}
            className="font-bold font-plus-jakarta-sans text-[22px] mb-4 self-stretch"
          />
          <p className="leading-6">{description}</p>
          {!(id === '3d-discover') && <button
            type="button"
            className="text-[#205FF4] w-fit border-[#205FF44D] border text-sm font-bold py-3 px-4 rounded-lg mt-10 hover:bg-[#a2beff26] z-[2]"
            onClick={handleCta}
          >
            {cta}
          </button>}
        </div>
      </div>
    </div>
  );
}

const useSectionShareFeature = create(() => ({
  data: [
    {
      title: 'AR View',
      description: 'Bring your projects to life with AR. Share and view them in augmented reality through your mobile devices.',
      color: '#1B5174',
      background: '#ACD3EC',
      boxShadow: '0px 4px 10px 0px rgba(27, 81, 116, 0.25)',
      image: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/share-feature-01.png',
      imageClassName: 'min-w-[110%] mw:-right-[20%] mw:translate-x-0',
      imageContainerClassName: 'lg:mt-0 lg:self-center',
    },
    {
      title: 'Interactive 3D View',
      description: 'Share your projects in a captivating 3D format directly from our platform. Engage your audience',
      color: '#1E4846',
      background: '#B8E3E2',
      boxShadow: '0px 4px 10px 0px rgba(30, 72, 70, 0.25)',
      image: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/share-feature-02.png',
      imageClassName: 'min-w-[110%] mw:translate-x-0 mw:-right-[8%]',
      imageContainerClassName: 'lg:max-w-[300px]',
    },
    {
      title: 'Embed on Website',
      description: 'Easily embed your projects on any website. Enhance your web content with interactive 3D models using',
      color: '#09359F',
      background: '#A8C0FA',
      boxShadow: '0px 4px 10px 0px rgba(9, 53, 159, 0.25)',
      image: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/share-feature-03.png',
      imageClassName: 'min-w-[115%] mw:translate-x-0 mw:-right-[19%] lg:-translate-y-[56%] mw:-translate-y-[49%]',
      imageContainerClassName: 'lg:max-w-[300px]',
    },
    {
      title: 'Embed in Canva',
      description: 'Integrate your 3D projects seamlessly into your Canva designs. Enhance your presentations and graphics with',
      color: '#600396',
      background: '#ECCDFE',
      boxShadow: '0px 4px 10px 0px rgba(204, 120, 252, 0.25)',
      image: 'https://assemblrworld-asset.s3.ap-southeast-1.amazonaws.com/static/assets/share-feature-04.png',
      imageClassName: 'min-w-[115%] mw:translate-x-0 mw:-right-[16%] -translate-y-[45%]',
      imageContainerClassName: 'lg:mt-0 lg:self-center',
    },
  ],
}));

function SectionShareFeature() {
  const { data } = useSectionShareFeature();

  return (
    <div className="relative w-full">
      <div className="bg-home-shares-vector z-1" />
      <div className="flex flex-col w-full pt-10 lg:pt-[100px]">
        <div className="flex flex-col items-center justify-center w-full gap-10">
          <h2 className="font-plus-jakarta-sans font-light w-[calc(100vw-40px)] max-w-[450px] text-center text-[32px] !mb-0 tracking-[.32px]">Share & enliven your projects on your daily gadgets
          </h2>
        </div>
      </div>
      <div className={classnames('w-[calc(100vw-40px)] md:w-[calc(100vw-80px)] max-w-[1200px] grid py-[50px] gap-5 lg:py-[100px] mx-auto relative overflow-visible md:grid-cols-2')}>
        {
          data.map((item, index) => (
            <div
              key={index}
              className={classnames('max-w-full flex flex-col object-cover rounded-lg z-[2] p-5 lg:flex-row', {
                // 'md:col-span-3 mw:col-span-2': [1, 2].includes(index),
                // 'md:col-span-4 mw:col-span-3 lg:flex-row': [0, 3].includes(index),
              })}
              style={{
                background: item.background,
                boxShadow: item.boxShadow,
                color: item.color,
              }}
            >
              <div className="flex flex-col">
                <h1 className="font-semibold font-plus-jakarta-sans text-[18px] self-stretch">{item.title}</h1>
                <p className="leading-6 max-w-[420px] !mb-5">{item.description}</p>
              </div>
              <div className={classnames('flex justify-center items-center mt-auto w-full aspect-video self-center lg:self-end relative md:w-[30vw] max-w-[calc(1200px*35/100)] overflow-visible', item.imageContainerClassName)}>
                <img
                  src={item.image}
                  alt={item.title}
                  className={classnames('w-full absolute right-1/2 top-1/2 transform translate-x-1/2 -translate-y-1/2', item.imageClassName)}
                />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

const useExploreMoreContent = create((set) => ({
  data: [],
  loading: true,
  getExploreMoreContent: async () => {
    set({ loading: true });
    try {
      const response = await fetch('https://asblr.app/api/content/highlight/?order_by=-updated_at&user=explore.v2');
      const data = await response.json();
      set({
        data: data.slice(0, 4),
        loading: false,
      });
    } catch (error) {
      console.error(error);
      set({ loading: false });
    }
  },
}));

const useEnterprisesStore = create((set) => ({
  data: [],
  loading: true,
  getEnterprises: async () => {
    try {
      set({ loading: true });
      const response = await fetch('https://asblr.app/api/v2/enterprise/list-exclusive/');
      const data = await response.json();
      const withOwners = await Promise.all(data.enterprise.map(async (item) => {
        const owner = await AppServices.getUserInfo(item.HostID);
        return {
          ...item,
          owner,
        };
      }));
      console.log({ ___aio: withOwners[0].owner });
      set({ data: withOwners, loading: false });
    } catch (error) {
      console.error(error);
      set({ loading: false });
    }
  },
}));

function Home() {
  useFirebaseAuthCheck();
  const {
    query: {
      'reset-password': resetPassword = false,
      login = false,
      signup = false,
      version = null,
    },
  } = useRouter();

  const dispatch = useDispatch();

  useEffect(() => {
    if (resetPassword) {
      dispatch(setLogin('forgot'));
    } else if (login) {
      dispatch(setLogin('login'));
    } else if (signup) {
      dispatch(setLogin('register'));
    }
  }, [
    resetPassword,
    login,
    signup,
  ]);

  const sessionRecord = useFeatureFlagEnabled('session_replay');

  useEffect(() => {
    if (sessionRecord) {
      posthog.startSessionRecording();
    }

    return () => {
      if (sessionRecord) {
        posthog.stopSessionRecording();
      }
    };
  }, [sessionRecord]);

  useEffect(() => {
    dispatch(setUnityVersion(version));
  }, [version]);

  const { data: tutorials, selectedTutorial, setSelectedTutorial } = useTutorialStore();
  const { data: partners } = usePartnerStore();
  const { data: features } = useFeatureStore();
  const { data: exploreMoreContent, loading: exploreMoreLoading, getExploreMoreContent } = useExploreMoreContent();
  const {
    data: plans, loading: plansLoading, getServerData, iapId,
  } = usePlanStore();
  const { data: enterprises, getEnterprises } = useEnterprisesStore();

  useEffect(() => {
    getExploreMoreContent();
    if (userAgent.isMobile.Assemblr()) {
      getEnterprises();
    }
    // getServerData();
  }, []);

  const { user } = useAuth();

  useEffect(() => {
    document.body.style.overflow = selectedTutorial ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [selectedTutorial]);

  const handleClickTutorialCard = async ({ id, title, youtube_id: youtubeId }) => {
    setSelectedTutorial(null);
    if (!user) {
      if (id !== '_blank') {
        loginPopupAnalyticTracking(`tutorial_${id + 1}`, { title, youtubeId });
      }
      sessionStorage.setItem('triggerTutorial', JSON.stringify({ id, title, youtubeId }));
      dispatch(setLogin('login'));
    }
  };

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const stickyButtonVariants = {
    hidden: {
      y: 100,
      opacity: 0,
      border: 'none',
    },
    visible: {
      y: 0,
      opacity: 1,
      border: '1px solid rgb(229, 231, 235)'
    },
  };

  const { openIframePopup } = useIframeHelper();

  const { push } = useRouter();

  const refTutorial = useScrollGrab({ data: tutorials, childSelector: '.tutorial-card' });
  const refPlan = useScrollGrab({ data: plans, childSelector: '.plan-card' });
  const refExploreMore = useScrollGrab({ data: exploreMoreContent, childSelector: '.explore-more-card' });
  const refPartner = useScrollGrab({ data: partners, childSelector: '.partner-card' });

  return (
    <div className="mx-auto flex flex-col items-center justify-center">
      {selectedTutorial && (
        <div className="scrim-loading max-h-full flex-col md:flex-row !justify-end md:!justify-center gap-3">
          <div
            aria-hidden
            onClick={() => setSelectedTutorial(null)}
            className="scrim_full"
          />

          <div
            className="self-end mr-3 umdlandscape:hidden md:absolute top-5 right-5 rounded-full bg-[#ffffff30] p-2 cursor-pointer z-50 hover:bg-[#ffffff50]"
            aria-hidden
            onClick={() => setSelectedTutorial(null)}
          >
            <MdClose color="#D3D6E9" size={16} />
          </div>

          <div
            className="umdlandscape:max-w-full md:max-w-[calc(100vw-80px)] max-h-full overflow-y-auto lg:max-w-[800px] w-full max-w-full flex flex-col z-50 md:rounded-lg umdlanscape:rounded-none text-white overflow-hidden self-end md:self-center"
            style={{
              background: selectedTutorial.color,
            }}
          >
            <div className="flex flex-col p-5 relative">
              <div
                className="absolute hidden umdlandscape:flex top-5 right-5 rounded-full bg-[#ffffff30] p-2 cursor-pointer z-50 hover:bg-[#ffffff50]"
                aria-hidden
                onClick={() => setSelectedTutorial(null)}
              >
                <MdClose color="#D3D6E9" size={16} />
              </div>
              <h1 className="text-[22px] font-semibold font-plus-jakarta-sans mb-[26px] mr-10">{selectedTutorial.detail.title}</h1>
              {/* <video className="w-full rounded-lg aspect-video bg-white" autoPlay loop muted playsInline poster={selectedTutorial.detail.poster}>
              <source src={selectedTutorial.detail.video} type="video/mp4" />
            </video> */}
              <iframe
                className="w-full rounded-lg aspect-video bg-white animate-pulse"
                src={`https://www.youtube.com/embed/${selectedTutorial.youtube_id}?autoplay=1&mute=1&controls=1&loop=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title={selectedTutorial.title}
                onLoad={(e) => {
                  e.target.classList.remove('animate-pulse');
                }}
              />
            </div>
            <div
              className="flex flex-col md:flex-row p-5 w-full justify-between items-center"
              style={{
                background: selectedTutorial.accent,
              }}
            >
              <div
                className="md:flex flex-col leading-6 overflow-hidden h-0 md:h-fit"
              >
                <h2 className="font-bold">{selectedTutorial.detail.subtitle}</h2>
                <p>{selectedTutorial.detail.description}</p>
              </div>
              <div className="flex flex-col leading-6 md:hidden">
                <p className="font-bold">{selectedTutorial.detail.subtitle} <span className="font-normal">{selectedTutorial.detail.description}</span></p>
              </div>
              <button
                type="button"
                className="text-white w-full text-nowrap md:w-fit text-sm font-bold py-3 px-4 rounded-lg border-2 border-[#ffffff30] font-plus-jakarta-sans hover:bg-[#ffffff30] mt-5 md:mt-0"
                onClick={() => { handleClickTutorialCard(selectedTutorial); }}
              >
                {selectedTutorial.detail.cta}
              </button>
            </div>
          </div>
        </div>
      )}
      <motion.div
        className={classnames('bg-white border-none py-2 w-0 mw:w-full items-center justify-center fixed bottom-0 z-20 flex mw:!border mw:!border-[#e5e7eb] mw:!border-solid')}
        variants={stickyButtonVariants}
        initial="hidden"
        animate={hasScrolled ? 'visible' : 'hidden'}
      >
        <div className="md:w-[calc(100vw-40px)] items-center justify-between max-w-[1200px] hidden mw:flex mw:!bg-white">
          <p className="font-inter"><b>Create your own AR</b>, as easy as drag-and-drop</p>
          <button
            type="button"
            className="bg-[#205FF4] text-white text-sm font-bold py-2 px-6 rounded-lg hover:bg-[#0C55C6] min-w-fit"
            onClick={() => {
              handleClickTutorialCard({ id: '_blank', title: '_blank', youtube_id: '_blank' });
            }}
          >
            Let&apos;s get started
          </button>
        </div>
      </motion.div>

      <div className="w-[calc(100vw-40px)] md:w-[calc(100vw-80px)] flex flex-col max-w-[600px] lg:max-w-full items-center justify-center text-center mx-auto my-[50px] lg:mt-[60px] lg:mb-[100px]">
        <h1 className="text-[40px] font-bold mb-[14px] font-plus-jakarta-sans">Create your own interactive AR/3D in minutes</h1>
        <p className="text-xl font-light lg:max-w-[800px]">Meet Assemblr Studio, an all-in-one platform to create and enliven your 3D/AR experiences—as easy as drag-and-drop, no coding needed.</p>
        <button
          type="button"
          className="bg-[#205FF4] text-white text-sm font-bold py-3 px-4 rounded-lg mt-[42px] hover:bg-[#0C55C6]"
          onClick={() => {
            handleClickTutorialCard({ id: '_blank', title: '_blank', youtube_id: '_blank' });
          }}
        >
          Start Creating
        </button>
      </div>

      <div className="flex flex-col items-center justify-center w-full">
        <h2 className="w-[calc(100vw-40px)] md:w-[calc(100vw-80px)] text-center font-bold text-[22px] font-plus-jakarta-sans">What will you create today?</h2>
        <div
          ref={refTutorial}
          className="flex gap-5 mw-justify-center w-full overflow-x-scroll mw:justify-center no-scrollbar pt-6 pb-[50px] lg:pb-[100px]"
        >
          {
            tutorials.map((item, index) => (
              <TutorialCard
                {...item}
                key={index}
                isFirst={index === 0}
                isLast={index === tutorials.length - 1}
                // onClick={() => handleClickTutorialCard({ id: index, ...item })}
                onClick={() => setSelectedTutorial({ id: index, ...item })}
              />
            ))
          }
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full">
        <h2 className="w-[calc(100vw-40px)] md:w-[calc(100vw-80px)]  max-w-full md:max-w-[467px] text-center font-bold text-[22px] mb-7 font-plus-jakarta-sans">We’ve teamed up with your favorite brands, all over the world
        </h2>
        <div className="carousel-container-faded">
          <Marquee
            className="mw:w-[calc(100vw-40px)] max-w-[1200px]"
          >
            <div
              className="flex gap-5 justify-between no-scrollbar mr-10 items-center"
            >
              {
                partners.map((item, index) => (
                  <img
                    draggable="false"
                    key={index}
                    src={item.image}
                    style={{ height: `${item.height * 1.5}px` }}
                    alt={item.title}
                    className={classnames('w-[auto]')}
                  />
                ))
              }
            </div>
          </Marquee>
        </div>
      </div>
      <div className="flex flex-col w-full bg-home-3d-discover mt-[50px] pt-10 lg:pt-[100px]">
        <div className="flex flex-col items-center justify-center w-full gap-10">
          <h2 className="font-plus-jakarta-sans font-light w-[calc(100vw-40px)] max-w-[400px] text-center text-[32px] !mb-0 tracking-[.32px]">Get creative and have fun, all in a few steps
          </h2>
        </div>
        {
          features.slice(0, 1)
            .map((item, index) => (
              <SectionFeature
                key={item.key}
                id={item.key}
                title={item.title}
                description={item.description}
                cta={item.cta}
                reverse={index % 2 === 1}
                handleCta={() => {
                  if (item.url) {
                    if (item.nextRouter) {
                      push(item.url);
                    } else if (userAgent.isMobile.Assemblr()) {
                      window.location = `assemblr://openurl?url=${item.url}`;
                    } else {
                      window.open(item.url, '_blank');
                    }
                  }
                }}
                image={item.image}
                video={item.video}
                thumbnail={item.thumbnail}
              />
            ))
        }
      </div>

      {
        features.slice(1, 3)
          .map((item, index) => (
            <SectionFeature
              key={item.key}
              id={item.key}
              title={item.title}
              description={item.description}
              cta={item.cta}
              reverse={index % 2 === 0}
              thumbnail={item.thumbnail}
              handleCta={() => {
                if (item.url) {
                  if (item.nextRouter) {
                    push(item.url);
                  } else if (userAgent.isMobile.Assemblr()) {
                    window.location = `assemblr://openurl?url=${item.url}`;
                  } else {
                    window.open(item.url, '_blank');
                  }
                }
              }}
              image={item.image}
              video={item.video}
            />
          ))
      }

      <SectionShareFeature />

      {/* <div className={classnames('flex flex-col items-center justify-center w-full py-10 md:py-20 bg-[#34456B]', {
        '!pb-0': userAgent.isMobile.any(),
      })}
      >
        <h2 className="w-[calc(100vw-40px)] md:w-[calc(100vw-80px)] font-plus-jakarta-sans text-center text-[32px] text-white tracking-[.32px] font-light"><b>Choose the plan</b> that suits you best</h2>
        <div
          ref={refPlan}
          className="flex gap-5 w-full overflow-x-scroll mw:justify-center no-scrollbar pt-10 pb-20 plan-card"
        >
          {
            plans.map((item, index) => (
              <div
                key={index}
                className={classnames('w-[300px] !max-w-full min-w-[300px] mw:w-[285px] mw:min-w-0 flex flex-col cursor-pointer relative rounded-xl p-5 aspect-square overflow-hidden justify-between', {
                  'ml-5 md:ml-10 mw:ml-0': index === 0,
                  'mr-5 md:mr-10 mw:mr-0': index === plans.length - 1,
                })}
                style={item.style}
              >
                <p className="font-plus-jakarta-sans font-bold text-[28px] max-w-[100px]">{item.title}</p>
                <img src={item.thumbnail} alt={item.title} className="absolute top-5 -right-2" />
                <div className="flex flex-col gap-5">
                  <p className="" dangerouslySetInnerHTML={{ __html: item.description }} />
                  <button
                    type="button"
                    className={classnames('w-full flex justify-between items-center bg-[#205FF4] text-white border-[#205FF44D] border text-sm font-bold py-3 px-4 rounded-lg  hover:opacity-95 text-[14px] z-[2]', {
                      '!justify-center': !item.price,
                    })}
                    onClick={() => {
                      if (item.cta?.onClick === 'signup') {
                        dispatch(setLogin('login'));
                      } else if (item.cta?.url) {
                        if (userAgent.isMobile.Assemblr()) {
                          sessionStorage.setItem('redirectPage', '/account/plan');
                          // sessionStorage.setItem('triggerIap', `assemblr://iap?productid=com.assemblrworld.subscription.${iapId(item.key)}&platform=0&redirect_uri=/account/plan`);
                          dispatch(setLogin('login'));
                        } else {
                          push(item.cta.url, undefined, { scroll: false });
                        }
                        // dispatch(setLogin('login'));
                      }
                    }}
                    disabled={
                      userAgent.isMobile.Assemblr() && item.key !== 'free' && (!iapId(item.key) || plansLoading)
                    }
                  >
                    {item.cta?.text}
                    {item.price && (
                      <div className="flex items-center">
                        <span className="text-[16px] font-bold">${item.price}</span><span className="text-[#ffffff80]">/Month</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            ))
          }
        </div>
        {!userAgent.isMobile.any() && (
          <button
            type="button"
            className="w-fit -mt-10 border-2 border-[#ffffff30] text-white text-sm font-bold py-3 px-4 rounded-lg  hover:bg-[#a2beff26] z-20"
            onClick={() => {
              openIframePopup('plan');
            }}
          >
            Compare all plan features
          </button>
        )}
      </div> */}

      {enterprises.length > 0 && userAgent.isMobile.Assemblr() && (
        <div className="w-full bg-white flex flex-col items-ceter">
          <h2 className="w-[calc(100vw-40px)] text-center font-light font-plus-jakarta-sans mx-auto text-[32px] mt-10 md:mt-[60px] !mb-0">Special Contents from our Partners</h2>
          <div
            ref={refPartner}
            className="flex h-fit min-h-fit gap-5 md:gap-[25px] mw:max-w-[1200px] overflow-x-scroll no-scrollbar my-[50px]"
          >
            {
              enterprises.map((item, index) => (
                <div
                  key={index}
                  className={classnames('w-[140px] h-[140px] rounded-xl min-w-[140px] min-h-[140px] relative partner-card', {
                    'ml-5 md:ml-10 mw:ml-0': index === 0,
                    'mr-5 md:mr-10 mw:mr-0': index === enterprises.length - 1,
                  })}
                  aria-hidden
                  onClick={() => { push(`/profile/${item.owner?.Username}`); }}
                >
                  <img
                    src={item.PublishedThumbnail}
                    onError={
                      (e) => {
                        e.target.onerror = null;
                        e.target.src = '/assets/images/home/white.png';
                      }
                    }
                    alt=""
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-xl"
                  />
                  <div className="partner-bg absolute top-0 left-0 w-full h-full rounded-xl" />
                  <div className="absolute flex flex-col items-center justify-center bottom-0 left-0 w-full h-full gap-3 p-3 truncate px-3 overflow-hidden">
                    <ImageResizer
                      src={item.owner?.ProfilePictureURL}
                      alt=""
                      className="w-12 h-12 rounded-full border-solid bg-white border-2 border-white"
                    />
                    <p className="text-white font-semibold line-clamp-1 w-full text-center truncate">{item?.owner?.Name}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )}
      <div className="w-full bg-[#F4F4F4] flex flex-col items-ceter px-5 md:px-0">
        <h2 className="w-[calc(100vw-40px)] text-center font-light font-plus-jakarta-sans mx-auto text-[32px] mt-10 md:mt-20 !mb-0">Explore more stuff about us!</h2>
        <div
          ref={refExploreMore}
          className="flex flex-col mw:mx-auto md:flex-row h-fit min-h-fit w-full gap-5 md:gap-[25px] mw:max-w-[1200px] overflow-x-scroll no-scrollbar mb-32 lg:mb-20 mt-10"
        >
          {
            exploreMoreContent.map((item, index) => (
              <>
                <div
                  key={index}
                  className={classnames('w-full md:w-[261px] md:min-w-[261px] mw:w-full max-w-full flex md:flex-col gap-[15px] md:gap-0 explore-more-card', {
                    'md:ml-10 mw:ml-0': index === 0,
                    'md:mr-10 mw:mr-0': index === exploreMoreContent.length - 1,
                  })}
                  aria-hidden
                >
                  <img src={item.thumbnails} alt={item.title} className="aspect-[6/4] w-[110px] h-[80px] md:h-[180px] md:w-full rounded-lg" />
                  <div className="flex flex-col md:mt-[10px]">
                    <h3 className="font-semibold md:font-bold font-plus-jakarta-sans md:my-[10px] text-[14px] md:text-[18px] mb-[10px]">{item.title}</h3>
                    <p className="text-[#0D1216DB] text-[13px] line-clamp-5 leading-[140%] h-0 md:h-fit md:inline-block">{item.description}</p>
                    <div
                      aria-hidden
                      onClick={() => {
                        if (userAgent.isMobile.Assemblr()) {
                          window.location = `assemblr://openurl?url=${item.link.url}`;
                        } else {
                          window.open(item.link.url, '_blank');
                        }
                      }}
                      className="md:pt-6 gap-2 text-[#205FF4] text-[12px] flex items-center cursor-pointer opacity-90 hover:opacity-100 font-plus-jakarta-sans font-semibold hover:underline"
                    >
                      {item.link.label}
                      <svg xmlns="http://www.w3.org/2000/svg" className="mt-1" width="14" height="12" viewBox="0 0 14 13" fill="none">
                        <path d="M5.91675 1H3.25008C2.89646 1 2.55732 1.14048 2.30727 1.39052C2.05722 1.64057 1.91675 1.97971 1.91675 2.33333V10.3333C1.91675 10.687 2.05722 11.0261 2.30727 11.2761C2.55732 11.5262 2.89646 11.6667 3.25008 11.6667H11.2501C11.6037 11.6667 11.9428 11.5262 12.1929 11.2761C12.4429 11.0261 12.5834 10.687 12.5834 10.3333V7.66667M7.25008 6.33333L12.5834 1M12.5834 1V4.33333M12.5834 1H9.25008" stroke="#205FF4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>
                {
                  index < exploreMoreContent.length - 1 && (
                    <div className="h-[1px] md:h-[409px] max-h-full bg-[#D3D6E9] min-w-[1px] text-transparent" />
                  )
                }
              </>
            ))
          }
          {
            exploreMoreLoading && exploreMoreContent.length === 0
            && new Array(4).fill(null).map((_, index) => (
              <>
                <div
                  key={index}
                  className={classnames('w-full md:w-[261px] gap-[15px] md:gap-0 md:min-w-[261px] flex md:flex-col animate-pulse md:h-[380px]', {
                    'md:ml-10 mw:ml-0': index === 0,
                    'md:mr-10 mw:mr-0': index === 3,
                  })}
                >
                  <div className="w-[110px] min-w-[110px] md:w-[261px] md:min-w-[261px] h-[80px] md:h-[180px] rounded-lg bg-gray-200" />
                  <div className="flex flex-col w-full">
                    <div className="font-semibold md:mt-[10px] h-4 bg-gray-200 rounded-lg" />
                    <div className="font-semibold mt-1 h-4 bg-gray-200 rounded-lg w-3/4" />
                    <div className="font-semibold mt-0 md:mt-3 h-0 md:h-4 bg-gray-200 rounded-lg" />
                    <div className="font-semibold mt-0 md:mt-1 h-0 md:h-4 bg-gray-200 rounded-lg" />
                    <div className="font-semibold mt-0 md:mt-1 h-0 md:h-4 bg-gray-200 rounded-lg w-3/4" />
                    <div className="flex justify-between mt-4 md:mt-6">
                      <div className="font-semibold h-4 bg-gray-200 rounded-lg w-3/4 mt-auto" />
                      <div className="font-semibold h-4 bg-gray-200 rounded-lg w-4 mt-auto" />
                    </div>
                  </div>
                </div>

                {
                  index < 3 && (
                    <div className="h-[1px] md:h-[409px] max-h-full bg-[#D3D6E9] animate-pulse min-w-[1px] text-transparent" />
                  )
                }
              </>
            ))
          }
        </div>
      </div>
    </div>
  );
}

Home.getLayout = function getLayout(page) {
  return <MainLayout className="!bg-white">{page}</MainLayout>;
};

export default Home;
